import React, { useCallback, useContext, useMemo, useState } from "react";
import { Modal, Button } from "antd";
// API
import { onUpdateOptionButton } from "api/teamRunApi";
// Helpers
import { toast } from "helpers/generalHelper";
// Style
import "./UpdateOption.scss";
import { ACTION_BUTTONS_NAME } from "constants/generalActionButtons";
import { GlobalContext } from "contexts/GlobalContext";
import CompanyTraders from "components/EditTradeDetails/CompanysTrader";
import { _ } from "ag-grid-community";
import { EDIT_TRADE_DETAILS_LABELS } from "constants/auditTrail";
import AddCompanyForm from "../AddCompanyForm";

const BUTTONS_NAME = {
  UPDATE_BID: {
    label: "Update bid",
    key: "bid",
    color: "bid_button_color",
    show: true,
  },
  UPDATE_ASK: {
    label: "Update ask",
    key: "ask",
    color: "ask_button_color",
    show: true,
  },

  UPDATE_MARKET: {
    label: "Update market",
    key: "market",
    color: "market_button_color",
    show: true,
  },
};

const AlignedLabelAndData = (props) => {
  return (
    <div className="align-label-data__container">
      <h6 className={props.theme ? "label-light" : "label-dark"}>
        {props.label}
      </h6>
      <span dangerouslySetInnerHTML={{ __html: props.value }} />
    </div>
  );
};

const UpdateOption = (props) => {
  const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
  const [refetchCompanies, setRefetchCompanies] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentButton, setCurrentButton] = useState();
  const [tradingCompanies, setTradingCompanies] = useState(props.trader);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const onFooterClick = useCallback(
    (keyButton) => {
      console.log(keyButton);
      setCurrentButton(keyButton);
      if (
        tradingCompanies
          .map((item) => item.key?.startsWith(keyButton) && item.value)
          .includes(null)
      )
        return toast("Please add a valid trader name", "warning");

      const extractTradingCompanies = {
        ask_trading_company_id: tradingCompanies.find(
          (item) => item.key === "ask_trading_company_id"
        )?.value,
        bid_trading_company_id: tradingCompanies.find(
          (item) => item.key === "bid_trading_company_id"
        )?.value,
      };
      //remove null value
      Object.keys(extractTradingCompanies).forEach((key) => {
        if (extractTradingCompanies[key] === null) {
          delete extractTradingCompanies[key];
        }
      });
      const prepareAPIData = {
        summary_id: props.summaryId,
        type: keyButton,
        trading_companies: extractTradingCompanies,
      };

      setIsLoading(true);
      onUpdateOptionButton(prepareAPIData)
        .then((res) => {
          toast(res?.data?.message, "success");
          setIsLoading(false);
          props.onClose(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [tradingCompanies]
  );

  const customizedFooter = useMemo(() => {
    return Object.values(BUTTONS_NAME).map((item) => {
      if (item.key === "ask") item.show = props.colors?.show_ask_button;

      if (item.key === "bid") item.show = props.colors?.show_bid_button;

      if (item.key === "market") item.show = props.colors?.show_market_button;

      return (
        item.show && (
          <Button
            loading={item.key === currentButton && isLoading}
            key={item.key}
            onClick={() => onFooterClick(item.key)}
            className="footer"
            style={{
              border: `1px solid ${props.colors?.[item.color]}`,
              color: props.colors?.[item.color],
            }}
          >
            {item.label}
          </Button>
        )
      );
    });
  }, [onFooterClick, isLoading]);

  const handleTraderChange = (index, e) => {
    const updatedTraders = [...tradingCompanies];
    updatedTraders[index].value = e;
    setTradingCompanies(updatedTraders);
  };
  function onAddCompany() {
    setIsAddCompanyOpen(true);
  }

  return (
    <>
      <Modal
        getContainer={"#content-root"}
        width={"55vw"}
        open={props.isOpen}
        title={ACTION_BUTTONS_NAME.UPDATE_OPTION.label}
        onCancel={() => props.onClose(false)}
        footer={[
          <Button
            key={EDIT_TRADE_DETAILS_LABELS.TRADING_COMPANY}
            onClick={() => {
              onAddCompany();
            }}
            type="ghost"
          >
            {EDIT_TRADE_DETAILS_LABELS.TRADING_COMPANY}
          </Button>,
          ...customizedFooter,
        ]}
        className={`update-option__container ${dark_Theme && "ant-modal-dark"}`}
      >
        {props.data.map((item) => {
          return (
            <React.Fragment key={`${item.value}-${item.label}`}>
              <AlignedLabelAndData  label={item.label} value={item.value} theme={dark_Theme} />
            </React.Fragment>
          );
        })}
        {tradingCompanies.map((item, index) => (
          <div key={index} className="trader-input__container">
            <h6 className={dark_Theme ? "label-light" : "label-dark"}>
              {item.label}
            </h6>
            <div style={{ width: "50%" }}>
              <CompanyTraders
                defaultValue={item.value}
                onChange={(e) => handleTraderChange(index, e)}
                placeholder="Select Trader Company"
                isPopup={true}
                value={item?.value || ""}
                refetchCompanies={refetchCompanies}
                setRefetchCompanies={setRefetchCompanies}
                
              />
            </div>
          </div>
        ))}
      </Modal>
      {isAddCompanyOpen && (
        <AddCompanyForm
          setRefetchCompanies={setRefetchCompanies}
          isOpen={isAddCompanyOpen}
          setIsOpen={setIsAddCompanyOpen}
        />
      )}
    </>
  );
};

export default UpdateOption;
