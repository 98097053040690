import api from "./api";
import { toast } from "helpers/generalHelper";

//// TEAM RUN APIs ////
const TEAM_RUN_END_POINT = "/team-run";
const TEAM_RUN_MEMBERS_END_POINT = `${TEAM_RUN_END_POINT}/get-members`;
const PULL_TO_PRICER_END_POINT = `${TEAM_RUN_END_POINT}/pull-to-pricer`;
const ARCHIVE_OPTION_END_POINT = `${TEAM_RUN_END_POINT}/archive`;
const TEAM_RUN_ROWS_END_POINT = `${TEAM_RUN_END_POINT}/rows`;
const HIGHLIGHT_OPTION_END_POINT = `${TEAM_RUN_END_POINT}/highlight`;
const ACTIVE_OPTION_END_POINT = `${TEAM_RUN_END_POINT}/active`;
const PUSH_CHECK_END_POINT = `${TEAM_RUN_END_POINT}/push-check`;
const UPDATE_PREMIUM_END_POINT = `${TEAM_RUN_END_POINT}/premium`;
const NOTIFICATION_END_POINT = `${TEAM_RUN_END_POINT}/notifications`;
const UPDATE_DELTA_END_POINT = "update-delta";
const UPDATE_DELTA_QUANTITY_END_POINT = "update-delta-quantity";
const COLORS_END_POINT = "colors";
const FONT_COLOR_END_POINT = `${TEAM_RUN_END_POINT}/font-color`;

//// TRADE APIs ////
const TRADE_END_POINT = `${TEAM_RUN_END_POINT}/trade`;
const TRADING_COMPANIES_END_POINT = `${TRADE_END_POINT}/companies`;
const TRADERS_END_POINT = `${TRADE_END_POINT}/traders`;
const STORE_TRADE_END_POINT = `${TRADE_END_POINT}/store-trader`;

//// Trading Company APIs ////
const COMPANIES_SUGGESTIONS_END_POINT = `${TEAM_RUN_END_POINT}/trade/companies/suggestions`;
const STORE_COMPANY_END_POINT = `${TEAM_RUN_END_POINT}/trade/store-company-with-trader`;

//// MARKET DEPTH APIs ////
const MARKET_DEPTH_END_POINT = `${TEAM_RUN_END_POINT}/market-depth`;
const MARKET_DEPTH_TRADING_COMPANIES_END_POINT = `${MARKET_DEPTH_END_POINT}/trading-companies`;
const STORE_MARKET_DEPTH_END_POINT = `${MARKET_DEPTH_END_POINT}/store`;
const UPDATE_MARKET_DEPTH_END_POINT = `${MARKET_DEPTH_END_POINT}/update`;
const DELETE_MARKET_DEPTH_END_POINT = `${MARKET_DEPTH_END_POINT}/delete`;

export const handlePullToPricerClickTeamRun = (id) => {
  if (!id) return;
  api.post(`${PULL_TO_PRICER_END_POINT}?summary_id=${id}`).then((res) => {
    toast(res?.data?.message, "success");
  });
};

export const archiveOption = (summaryId) => {
  return api.post(`${ARCHIVE_OPTION_END_POINT}?summary_id=${summaryId}`);
};

export const archiveOptions = (summaryIds) => {
  return api.post(ARCHIVE_OPTION_END_POINT, { summary_ids: summaryIds });
};

export const getTeamRunProductById = async (productId) => {
  return await api.get(`${TEAM_RUN_ROWS_END_POINT}?product_id=${productId}`);
};

export const highLightAPI = async (summaryId) => {
  return await api.post(
    `${HIGHLIGHT_OPTION_END_POINT}?summary_id=${summaryId}`
  );
};

export const makeActiveInActiveAPI = async (summaryIds, isActive) => {
  return await api.post(ACTIVE_OPTION_END_POINT, {
    summary_ids: summaryIds,
    is_active: isActive,
  });
};

export const triggerSingleAPI = async (productId) => {
  return (await api.get(`${TEAM_RUN_END_POINT}?product_id=${productId}`))?.data;
};

export const triggerPushOptionToTeamRunAPI = async (body) => {
  return await api.post(PUSH_CHECK_END_POINT, {}, { ...body });
};

export const updateTradeDeltaQuantity = async (body) => {
  return await api.post(
    `${TRADE_END_POINT}/${
      body.delta ? UPDATE_DELTA_END_POINT : UPDATE_DELTA_QUANTITY_END_POINT
    }`,
    { ...body }
  );
};

export const onUpdateOptionButton = async (body) => {
  return await api.post(UPDATE_PREMIUM_END_POINT, {}, { ...body });
};

export const onTableClick = async (productId) => {
  return await api.post(NOTIFICATION_END_POINT, { product_id: productId });
};

export const getColors = async () => {
  return await api.get(COLORS_END_POINT);
};

export const setColorForRow = async (queryParams) => {
  return await api.get(FONT_COLOR_END_POINT, { ...queryParams });
};

export const getTradingCompanies = async () => {
  return await api.get(TRADING_COMPANIES_END_POINT);
};

export const getMarkedDepthTradingCompanies = async (optionId, isAsk) => {
  return await api.get(
    `${MARKET_DEPTH_TRADING_COMPANIES_END_POINT}?team_run_option_id=${optionId}&is_ask=${isAsk}`
  );
};

export const getMarkedDepth = async (params) => {
  return await api.get(MARKET_DEPTH_END_POINT, { ...params });
};

export const storeMarkedDepth = async (body) => {
  return await api.post(STORE_MARKET_DEPTH_END_POINT, {}, { ...body });
};

export const updateMarkedDepth = async (body) => {
  return await api.post(UPDATE_MARKET_DEPTH_END_POINT, { ...body });
};

export const deleteMarkedDepth = async (body) => {
  return await api.post(DELETE_MARKET_DEPTH_END_POINT, { ...body });
};

export const getTradersByTradingCompanyId = async (tradingCompany) => {
  return await api.get(
    `${TRADERS_END_POINT}?trading_company_id=${tradingCompany}`
  );
};

export const AddTrader = async (body) => {
  return await api.post(STORE_TRADE_END_POINT, { ...body });
};

export const getTeamMembers = async () => {
  return await api.get(TEAM_RUN_MEMBERS_END_POINT);
};

export const addCompanyAndTrader = async (body) => {
  return await api.post(STORE_COMPANY_END_POINT, { ...body });
};

export const getCompanySuggestions = async (params) => {
  return await api.get(COMPANIES_SUGGESTIONS_END_POINT, { ...params });
};