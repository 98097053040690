import { AutoComplete, Button, Form, Input, Modal, Spin } from "antd";
import {addCompanyAndTrader, getCompanySuggestions } from "api/teamRunApi";
import { debounce } from "lodash";

import {
  ADD_Company_MODAL_INFO,
  ADD_TRADE_MODAL_INFO,
} from "constants/teamRun";
import { GlobalContext } from "contexts/GlobalContext";
import { useCallback, useContext, useState } from "react";

function AddCompanyForm({ isOpen, setIsOpen = () => {},setRefetchCompanies }) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [traderName, setTraderName] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(null);
  const getPanelValue = (searchText) => (!searchText ? [] : companies);

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const fetchTradingCompanies = async (name) => {
    setIsDisabled(true);
    setIsChecking(true);
    await getCompanySuggestions({ name })
      .then((res) => {
        if (res?.status === 200) {
          setCompanies(
            res?.data?.data?.map((item) => ({
              value: `${item} (Already Exists)`,
            }))
          );
          setIsDisabled(false);
          setIsChecking(false);
          setError(null);
        } else {
          setIsDisabled(true);
          setIsChecking(false);
          setError(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsDisabled(true);
        setIsChecking(false);
        setError(err);
        console.log("Error >>", err);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDebounced = useCallback(
    debounce((value) => {
      if (!value) return;
      fetchTradingCompanies(value);
    }, 500),
    []
  );

  const onFinish = async () => {
    setIsLoading(true);
    await addCompanyAndTrader({
      company_name: companyName,
      trader_name: traderName,
    })
      .then((_) => {
        setIsLoading(false);
        setRefetchCompanies(true);
        setIsOpen(false);
      })
      .catch((_) => {
        setIsLoading(false);
      });
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const onSelect = (value) => {
    setCompanyName(value.replace(" (Already Exists)", ""));
  };

  return (
    <div className="">
      <Modal
        open={isOpen}
        width={"35%"}
        centered
        className={dark_Theme && "ant-modal-dark content_dark"}
        onCancel={hideModal}
        title={ADD_Company_MODAL_INFO.ADD_TRADING_COMPANY.label}
        bodyStyle={{ height: "250px", overflow: "auto" }}
        footer={[
          <Button disabled={isLoading} key="back" onClick={hideModal}>
            {ADD_TRADE_MODAL_INFO.CANCEL.label}
          </Button>,

          <Button
            onClick={onFinish}
            loading={isLoading}
            disabled={isDisabled || isLoading || !traderName}
            key="submit"
            type="primary"
          >
            {ADD_TRADE_MODAL_INFO.FINISH.label}
          </Button>,
        ]}
      >
        <Form
          name="basic"
          className="w-100 h-100 m-auto gap-3 d-flex flex-column justify-content-center align-items-center"
          autoComplete="off"
        >
          <div
            style={{
              color: dark_Theme
                ? "var(--color-white-1)"
                : "var(--color-white-1)",
              height: "20px",
            }}
            className="fw-bold w-100 text-center"
          >
            {isChecking && "Checking if the company already exists..."}
          </div>

          <div style={{ width: "100%", position: "relative" }}>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <label  className="fw-medium fs-6">
                Company Name
              </label>
              <AutoComplete
                disabled={isLoading}
                value={companyName}
                options={options}
                style={{ width: "70%" }}
                popupClassName={
                  dark_Theme ? "dropdown-dark exist-warning" : "exist-warning"
                }
                onSelect={onSelect}
                onChange={(value) => {
                  setCompanyName(value);
                  setIsDisabled(true);
                  fetchDebounced(value.replace(" (Already Exists)", ""));
                }}
                onSearch={(text) => setOptions(getPanelValue(text))}
                placeholder="Enter Company Name"
              />
            </div>
            {isChecking && (
              <div
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <Spin size="small" />
              </div>
            )}
          </div>
          <div
            style={{ height: "20px", width: "70%" }}
            className="text-warning fw-bold  text-center"
          >
            {error && companyName && error}
          </div>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <label  className="fw-medium fs-6">
              Trader Name
            </label>

            <Input
              style={{ width: "70%" }}
              required
              disabled={isLoading}
              placeholder="Enter Trader Name"
              type="text"
              onChange={(e) => {
                setTraderName(e.target.value);
              }}
              value={traderName}
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default AddCompanyForm;
